import React from 'react'
import { createFromIconfontCN } from '@ant-design/icons'
import { Source } from 'types/source'
import useCreditCardStyles from './CreditCard.style'

const CardIcon = createFromIconfontCN({
  scriptUrl: '/credit-card-icons.js',
})
interface CreaditCardProps {
  card: Source
}

const CreditCard = ({ card }: CreaditCardProps): React.JSX.Element => {
  const { styles } = useCreditCardStyles()

  const getCardIcon = (cardNetwork: string): string => {
    switch (cardNetwork) {
      case 'amex':
        return 'iconicon_zhifuamex'
      case 'diners':
        return 'iconicon_zhifudinersclub'
      case 'mc':
        return 'iconicon_zhifumastercard'
      case 'visa':
        return 'iconicon_zhifuvisa'
      case 'maestro':
        return 'iconicon_zhifumaestro'
      case 'up':
        return 'iconicon_up'
      default:
        return ''
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.cardDetailsWrapper}>
        <div className={styles.cardTitle} data-testid="card-title">
          <span>{card.title}</span>
        </div>
        <div className={styles.cardDetails}>
          <div>
            <CardIcon
              className={`${getCardIcon(card.typeProperties.cardNetwork) === 'iconicon_up' ? 'network-up' : ''}`}
              type={getCardIcon(card.typeProperties.cardNetwork) || ''}
              style={{
                fontSize: 30,
              }}
            />
          </div>
          <div className={styles.cardNumber}>
            <span>{`${card.typeProperties.bin} •••• •••• ${card.typeProperties.last4}`}</span>
          </div>
          {card.preferredCurrency && <div className={styles.cardCurrency}>{card.preferredCurrency.toUpperCase()}</div>}
        </div>
      </div>
    </div>
  )
}

export default CreditCard
