import { ReactElement } from 'react'
import { IntlShape } from 'react-intl'
import { createFromIconfontCN, CopyOutlined } from '@ant-design/icons'
import { card } from '../../lang/definitions'
import { SelectOption } from '../../types/general'
import { ProfileGroup } from '../../types/profileGroups'
import { Entity } from '../../types/entity'
import { checkLuhn } from 'pages/Beneficiaries/utils'
import { amex, dinersclub, mastercard, unionpay, visa, placeholder } from 'assets/cards/minicards/index'
import { CardType } from 'types/source'
import { Button, notification } from 'antd'
import { useTranslation } from 'utils/helpers'
import { isDeviceType } from 'hooks'
import { DEVICE_TYPE } from 'utils/getDeviceType'

const DEFAULT_CARD_FORMAT = /(\d{1,4})/g

const CardIcon = createFromIconfontCN({
  scriptUrl: '/credit-card-icons.js',
})

export interface CardFormData {
  cvv: string
  expiryMonth: string
  expiryYear: string
  expiryDate: string
  pan: string
  title: string
  name?: string
  preferredCurrency: string
  availableToEntityIds?: string
  availableToProfileGroupIds?: string[]
}

export const sourceColumns = (language: string, intl: IntlShape) => [
  {
    title: '',
    dataIndex: '',
    align: 'left' as const,
    colSpan: 1,
    width: 50,
    key: 'icon',
    className: 'card-icons',
    render: (data: { typeProperties: { cardNetwork: string } }): ReactElement => {
      let icon
      switch (data.typeProperties.cardNetwork) {
        case 'amex':
          icon = 'iconicon_zhifuamex'
          break
        case 'diners':
          icon = 'iconicon_zhifudinersclub'
          break
        case 'mc':
          icon = 'iconicon_zhifumastercard'
          break
        case 'visa':
          icon = 'iconicon_zhifuvisa'
          break
        case 'maestro':
          icon = 'iconicon_zhifumaestro'
          break
        case 'up':
          icon = 'iconicon_up'
          break
        default:
          break
      }
      return (
        <CardIcon
          className={`icon-container ${icon === 'iconicon_up' ? 'network-up' : ''}`}
          type={icon || ''}
          style={{
            fontSize: 42,
          }}
        />
      )
    },
  },
  {
    title: intl.formatMessage(card['card.cardName']),
    dataIndex: 'title',
    key: 'title',
    align: 'left' as const,
  },
  {
    title: intl.formatMessage(card['card.number']),
    dataIndex: '',
    ellipsis: true,
    className: 'desktop-display',
    key: 'number',
    render: (data: { typeProperties: { bin: string; last4: number } }): ReactElement => {
      const space = ' \u00A0 '
      const dots = ' \u2022 \u2022 \u2022 \u2022 '
      return <div>{`${data.typeProperties.bin} ${space}${dots}${space} ${data.typeProperties.last4}`}</div>
    },
  },
  {
    title: intl.formatMessage(card['card.expiryDate']),
    dataIndex: '',
    key: 'cardExpiryDate',
    render: (data: { typeProperties: { expiryMonth: number; expiryYear: number } }): ReactElement => {
      return (
        <>
          {data.typeProperties.expiryMonth} / {data.typeProperties.expiryYear}
        </>
      )
    },
  },
  {
    title: intl.formatMessage(card['card.availableEntities']),
    dataIndex: 'availableToEntityIds',
    key: 'entities',
    render: (data: string[]): ReactElement => {
      return <>{data && data.length}</>
    },
  },
  {
    title: intl.formatMessage(card['card.preferredCurrency']),
    dataIndex: 'preferredCurrency',
    key: 'preferredCurrency',
    render: (data: string): ReactElement => {
      return <>{data && data.toUpperCase()}</>
    },
  },
]

export const buildProfileGroupOptions = (profileGroups: ProfileGroup[]): SelectOption[] =>
  profileGroups.map((group) => ({
    value: group.id,
    label: group.title,
  }))

export const buildAvailableEntityOptions = (cardAdminEntities: Entity[]): SelectOption[] =>
  cardAdminEntities.map((availableEntity: Entity) => {
    const { id, class: { person, corp, sme } = {} } = availableEntity
    const corpTitle = corp?.title || ''
    const smeTitle = sme?.title || ''
    const personFirstName = person?.name?.first || ''
    const personLastName = person?.name?.last || ''
    return {
      label: corpTitle || smeTitle || `${personFirstName} ${personLastName}`,
      value: id,
    }
  })

export const buildCurrencyOptions = (currencies: string[]): SelectOption[] =>
  currencies.map((currency) => ({
    value: currency.toLowerCase(),
    label: currency.toUpperCase(),
  }))

export const CARD_TYPES: CardType[] = [
  {
    displayName: 'Visa',
    type: 'visa',
    format: DEFAULT_CARD_FORMAT,
    startPattern: /^4/,
    gaps: [4, 8, 12],
    lengths: [16, 18, 19],
    code: {
      name: 'CVV',
      length: 3,
    },
    image: visa,
    network: 'visa',
  },
  {
    displayName: 'Mastercard',
    type: 'mastercard',
    format: DEFAULT_CARD_FORMAT,
    startPattern: /^(5[1-5]|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/,
    gaps: [4, 8, 12],
    lengths: [16],
    code: {
      name: 'CVC',
      length: 3,
    },
    image: mastercard,
    network: 'mc',
  },
  {
    displayName: 'American Express',
    type: 'amex',
    format: /(\d{1,4})(\d{1,6})?(\d{1,5})?/,
    startPattern: /^3[47]/,
    gaps: [4, 10],
    lengths: [15],
    code: {
      name: 'CID',
      length: 4,
    },
    image: amex,
    network: 'amex',
  },
  {
    displayName: 'Diners Club',
    type: 'dinersclub',
    format: /(\d{4})(\d{6})(\d{4})/,
    startPattern: /^(36|38|30[0-5])/,
    gaps: [4, 10],
    lengths: [14, 16, 19],
    code: {
      name: 'CVV',
      length: 3,
    },
    image: dinersclub,
    network: 'diners',
  },
  {
    displayName: 'UnionPay',
    type: 'unionpay',
    format: DEFAULT_CARD_FORMAT,
    startPattern: /^(62|81)/,
    gaps: [4, 8, 12],
    lengths: [14, 15, 16, 17, 18, 19],
    code: {
      name: 'CVN',
      length: 3,
    },
    image: unionpay,
    network: 'up',
  },
  {
    displayName: 'Maestro',
    type: 'maestro',
    format: DEFAULT_CARD_FORMAT,
    startPattern: /^(5018|5020|5038|6304|6703|6708|6759|676[1-3])/,
    gaps: [4, 8, 12],
    lengths: [12, 13, 14, 15, 16, 17, 18, 19],
    code: {
      name: 'CVC',
      length: 3,
    },
    image: mastercard,
    network: 'mc',
  },
]
export const formatCardId = (id: string): string => {
  if (!id || id.length < 8) return id
  const first4 = id.slice(0, 4)
  const last4 = id.slice(-4)
  const dots = ' •••• '
  return `${first4}${dots}${last4}`
}
export const useGetCardIdColumn = () => {
  const t = useTranslation()
  const isMobile = isDeviceType(DEVICE_TYPE.MOBILE)

  return () => ({
    title: t('card.form.cardId.label'),
    dataIndex: 'id',
    key: 'id',
    align: 'left' as const,
    render: (id: string): ReactElement => (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>{isMobile ? `...${id.slice(-2)}` : formatCardId(id)}</span>
        <Button
          icon={<CopyOutlined />}
          style={{ border: 'none' }}
          onClick={(event) => {
            event.stopPropagation()
            void navigator.clipboard.writeText(id)
            showCopySuccess(t)
          }}
        />
      </div>
    ),
  })
}
const showCopySuccess = (t: (key: string) => string) => {
  notification.success({
    message: t('card.copySuccess'),
    placement: 'topRight',
    duration: 1.5,
    closeIcon: null,
  })
}

export const getCardTypeByValue = (value: string): CardType | undefined =>
  CARD_TYPES.filter((cardType) => cardType.startPattern.test(value))[0]

export const getCardImage = (value: string): string => {
  const cardType = getCardTypeByValue(value)
  return cardType ? cardType.image : placeholder
}

export const formatCardNumber = (cardNumber: string): string => {
  const cardType = getCardTypeByValue(cardNumber)

  if (!cardType) return (cardNumber.match(/\d+/g) || []).join('')

  const format = cardType.format

  if (format && format.global) {
    return (cardNumber.match(format) || []).join(' ')
  }

  if (format) {
    const execResult = format.exec(cardNumber.split(' ').join(''))
    if (execResult) {
      return execResult
        .splice(1, 3)
        .filter((x) => x)
        .join(' ')
    }
  }

  return cardNumber
}

export const formatExpiryDate = (value: string): string => {
  const numValue = parseInt(value, 10)
  if (value.length === 1 && numValue >= 2 && numValue <= 9) {
    return `0${value}`
  }

  if (value === '1/') {
    return '01 / '
  }

  if (value.length === 3 || value.length === 4) {
    const firstTwo = value.slice(0, 2)
    const last = value.slice(2).replace('/', '')

    return `${firstTwo} / ${last}`
  } else if (value.length < 6) {
    return value.slice(0, 2)
  }
  return value
}

export const invalidCardNumberError = (cardNumber: string) => {
  const rawCardNumber = cardNumber.replace(/\s/g, '')
  const cardType = getCardTypeByValue(rawCardNumber)
  if (cardType && cardType.lengths) {
    const doesCardNumberMatchLength = cardType.lengths.includes(rawCardNumber.length)
    if (doesCardNumberMatchLength) {
      const isLuhnValid = checkLuhn(rawCardNumber)
      if (isLuhnValid) {
        return false
      }
    }
  }
  return true
}
