import * as Sentry from '@sentry/react'
import { getProfileSourcesOnFile } from 'api/card'
import useCurrentPayment from './useCurrentPayment'
import { QueryParams } from 'types/general'
import { Beneficiary } from 'types/beneficiary'
import * as beneficiariesApi from 'api/beneficiary'
import { useSession } from 'stores/session'

export default function useCurrentPaymentUtils() {
  const {
    state: { user },
  } = useSession()

  const {
    actions: { setCards, setBeneficiaries },
  } = useCurrentPayment()

  const getCards = async (): Promise<void> => {
    if (!user || !user?.activeProfileId) {
      return
    }

    try {
      const { sources } = await getProfileSourcesOnFile(user?.activeProfileId)
      setCards(sources)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const getBeneficiaries = async (): Promise<Beneficiary[] | void> => {
    const params: QueryParams = {
      limit: 0,
    }

    try {
      const { beneficiaries } = await beneficiariesApi.getBeneficiaries(params)
      return beneficiaries
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const setBeneficiariesState = async (): Promise<void> => {
    const beneficiariesFromQuery = await getBeneficiaries()
    beneficiariesFromQuery && setBeneficiaries([...beneficiariesFromQuery])
  }

  return {
    getCards,
    getBeneficiaries,
    setBeneficiariesState,
  }
}
