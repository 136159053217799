import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Select, Alert, Divider } from 'antd'
import creditCardType from 'credit-card-type'
import { pingCharge, sourceCreate } from 'api/card'
import { useInterval } from 'hooks/useInterval'
import { appendScript, removeScript } from 'utils/script'
import AlertMessagesContainer from 'components/AlertMessage/AlertMessagesContainer'
import CardModal from './CardModal'
import { buildAvailableEntityOptions, buildCurrencyOptions, buildProfileGroupOptions, CardFormData } from './utils'
import { SourceData } from 'types/source'
import { Charge, RedirectData } from 'types/charge'
import { BillhopError } from 'types/general'
import { useUtils } from 'hooks/useUtils'
import * as Sentry from '@sentry/react'
import { PaymentGateway, Rules } from 'types/rules'
import * as rulesApi from 'api/rules'
import { getProfileGroups } from 'api/barracks'
import { Profile } from 'types/profile'
import { Entity } from 'types/entity'
import acl from 'utils/acl'
import { ProfileGroup } from 'types/profileGroups'
import { ChargeStatus, NextActionType } from 'types/charge'
import CardNumberFormItem from './FormItems/CardNumberFormItem'
import CVVFormItem from './FormItems/CVVFormItem'
import ExpiryDateFormItem from './FormItems/ExpiryDateFormItem'
import { useActiveUser } from 'hooks'
import { useTranslation } from 'utils/helpers'
import { SpaceForm } from 'pages/Beneficiaries/components'
import { useSession } from 'stores/session'

declare global {
  interface Window {
    encryptData: (id: string) => void
  }
}

interface Props {
  cardNetworks: Array<string>
  profileId: string
  gateways: Array<PaymentGateway>
  options?: React.JSX.Element[]
  submitButtonLabel?: string
  refresh: (sourceId: string) => void
}

const CardForm = ({
  cardNetworks,
  profileId,
  gateways,
  submitButtonLabel,
  options,
  refresh,
}: Props): React.JSX.Element => {
  const t = useTranslation()
  const [form] = Form.useForm()
  const { getTranslatedErrorMessage } = useUtils()
  const {
    state: { iam, user, rules },
  } = useSession()
  const activeProfile = useActiveUser().profile

  const [charge, setCharge] = useState<Charge>()
  const [iFrameData, setIFrameData] = useState<RedirectData | undefined>()
  const [delay, setDelay] = useState<number | null>(null)
  const [processingState, setProcessingState] = useState<string | null>(null)
  const [count, setCount] = useState(0)
  const [sourceId, setSourceId] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const [errorCode, setErrorCode] = useState<string>()
  const [isCorp, setIsCorp] = useState<boolean>()
  const [availableEntities, setAvailableEntities] = useState<Entity[]>([])
  const [profileGroups, setProfileGroups] = useState<ProfileGroup[]>([])

  const isEntityCardManagedByAdmin = !!rules?.toggles?.cardManagedByAdmin?.rule
  const profileCurrencies = Object.keys(rules?.logic.currencies?.rule || {})

  const isCorpUser = (): void => {
    setIsCorp(!!activeProfile?.entity.class?.corp)
  }

  //Fetch all entityIds available for current user
  const entityIds =
    user?.profiles.map((profile: Profile) => {
      return profile.entityId
    }) || []

  const isCardAdmin = acl({
    iam: iam!,
    me: user!,
    kind: 'source',
    barracksId: user?.activeProfileId,
    action: 'create',
  })

  //Fetch rules for every entityId available for current user
  const getEntityRules = async (): Promise<void> => {
    try {
      const entityRules = await Promise.all(entityIds.map((id: string) => rulesApi.getEntityRules(id)))
      const availableEntitiesId: string[] = []
      //Check if entity has specific rule (cardManagedByAdmin and push them into a new array)
      entityIds.forEach((entityId, index) => {
        const entityRule = entityRules[index]
        if (entityRule.toggles?.cardManagedByAdmin?.rule) {
          availableEntitiesId.push(entityId)
        }
      })
      //We're mapping through an array of entityIds so we can find their entity data to display (ex: name)
      const availableEntities =
        availableEntitiesId.map((id: string) => {
          const profile = user?.profiles.find((profile) => profile.entityId === id)
          return profile!
        }) || []
      const profilesCardManagedByAdmin = await Promise.all(
        availableEntities.map((profile: Profile) => rulesApi.getProfileRules(profile.id))
      )

      const profileRulesList: { rule: Rules; profile: Profile }[] = []

      availableEntities.forEach((profile, index) => {
        const profileRule = profilesCardManagedByAdmin[index]
        if (profileRule.acl.source.profile.create.rule) {
          const data = {
            profile: profile,
            rule: profileRule,
          }
          profileRulesList.push(data)
        }
      })
      const profileEntities = profileRulesList.map((profile) => profile.profile.entity)

      setAvailableEntities(profileEntities)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const fetchProfileGroups = async (entityId: string): Promise<void> => {
    try {
      const profileGroups = await getProfileGroups(entityId)
      setProfileGroups(profileGroups)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const createSource = async (data: SourceData) => {
    try {
      const source = await sourceCreate(data)
      setSourceId(source.id)

      switch (source.status) {
        case 'REQUIRES_ACTION': {
          if (
            source.nextAction?.type === 'validationCharge' &&
            source.nextAction.charge.status === ChargeStatus.REQUIRES_ACTION &&
            source.nextAction.charge.nextAction?.type === NextActionType.REDIRECT
          ) {
            setCharge(source.nextAction.charge)
            const { redirect } = source.nextAction.charge.nextAction
            setIFrameData(redirect)
          }
          break
        }
        case 'SUCCESS': {
          setIsLoading(false)
          refresh(sourceId!)
          break
        }
        case 'FAIL': {
          setIsLoading(false)
          const { typeProperties: { validationCharge: { outcome } = {} } = {} } = source
          if (outcome) {
            setErrorCode(outcome.errorCode)
          }
          break
        }
        default: {
          setIsLoading(false)
          setErrorCode('GENERAL_ERROR')
        }
      }
    } catch (error) {
      setIsLoading(false)
      Sentry.captureException(error)
      const { details: { errorMessage = '' } = {} } = error as BillhopError
      if (errorMessage === 'BIN_BLOCKED') {
        setErrorCode('BIN_BLOCKED')
      }
    }
  }

  const captureCardBin = (pan: string): string => {
    if (pan.length >= 16) {
      return pan.substring(0, 8)
    } else {
      return pan.substring(0, 6)
    }
  }

  const onSubmit = (data: CardFormData) => {
    const [month, year] = data.expiryDate.split(' / ')
    const expiryMonth = parseInt(month)
    const expiryYear = parseInt(`20${year}`)
    setIsLoading(true)
    const { title, cvv, availableToProfileGroupIds, preferredCurrency, availableToEntityIds } = data

    const pan = data.pan.replace(/ /g, '')

    const bin = captureCardBin(pan)
    const last4 = pan.slice(-4)

    const { type } = creditCardType(pan)[0]

    const cardNetwork =
      {
        mastercard: 'mc',
        'american-express': 'amex',
        'diners-club': 'diners',
        maestro: 'mc',
        unionpay: 'up',
      }[type] || type

    const selectedGateway = gateways.find((gate) => gate.cardNetwork === cardNetwork)

    const sourceData: SourceData = {
      profileId,
      title,
      preferredCurrency,
      gatewayProperties: {
        expiryYear,
        expiryMonth,
        bin,
        last4,
        cardNetwork: cardNetwork,
      },
    }
    if (availableToEntityIds) {
      sourceData.availableToEntityIds = [availableToEntityIds]
    }
    if (availableToProfileGroupIds && availableToProfileGroupIds.length > 0) {
      sourceData.availableToProfileGroupIds = availableToProfileGroupIds
    }
    if (selectedGateway?.paymentGateway === 'piq') {
      selectedGateway &&
        appendScript(`${selectedGateway.gatewayAccount}`, () => {
          if (typeof window !== 'undefined') {
            const panEncrypted = window.encryptData(pan)
            const cvvEncrypted = window.encryptData(cvv)
            sourceData.gatewayProperties = {
              ...sourceData.gatewayProperties,
              ...{
                panEncrypted,
                cvvEncrypted,
              },
            }
            void createSource(sourceData)
          }
        })
    } else {
      void createSource(sourceData)
    }
  }

  const ping = async (): Promise<Charge | undefined> => {
    // call API for the source
    // Check if it's ok or failed
    if (charge) {
      return pingCharge(charge.id)
    }
  }

  useInterval(() => {
    if (iFrameData) {
      void ping().then((fetchedCharge) => {
        setCount(count + 1)
        setCharge(fetchedCharge)
      })
    }
  }, delay)

  useEffect(() => {
    setDelay(1000)
  }, [iFrameData])

  useEffect(() => {
    void isCorpUser()

    if (isCardAdmin) {
      void getEntityRules()
      void fetchProfileGroups(activeProfile.entityId)
    }
  }, [])

  useEffect(() => {
    setProcessingState(charge ? charge.status : null)
    if (charge && charge.status !== ChargeStatus.REQUIRES_ACTION) {
      setDelay(null)
      setIFrameData(undefined)
      if (charge.status === ChargeStatus.SUCCESS && charge.source) {
        removeScript(Number(charge.source.typeProperties.gateway.gatewayAccount))
      }
    }
  }, [charge])

  const renderErrorAlert = (): React.ReactNode => {
    if (!errorCode) return

    return (
      <Alert
        type="error"
        showIcon
        message={t('messages.error.cards.create')}
        description={getTranslatedErrorMessage(errorCode)}
      />
    )
  }

  return (
    <div className="bh-form">
      <CardModal
        data={iFrameData}
        activeCharge={charge}
        open={!!processingState}
        processingState={processingState}
        onClose={() => {
          setProcessingState(null)
        }}
        afterClose={() => {
          refresh(sourceId!)
          setIsLoading(false)
        }}
      />
      <AlertMessagesContainer placement="addCard" />
      <Form
        form={form}
        initialValues={{
          name: `${user?.user.name.first} ${user?.user.name.last}`,
          expiryDate: '',
          cvv: '',
          availableToEntityIds: activeProfile.entityId,
        }}
        name="source-form"
        layout="vertical"
        onFinish={onSubmit}
        requiredMark={false}
        autoComplete="off"
      >
        <SpaceForm>
          {renderErrorAlert()}

          <CardNumberFormItem cardNetworks={cardNetworks} />

          <Form.Item name="name" label={t('card.form.name.label')} hasFeedback>
            <Input size="large" name="name" placeholder={t('card.form.name.placeholder')} />
          </Form.Item>

          <ExpiryDateFormItem />

          <CVVFormItem />

          <Form.Item
            name="title"
            hasFeedback
            label={t('card.form.cardName.label')}
            rules={[
              {
                required: true,
                message: t('card.form.cardName.error.required'),
              },
            ]}
          >
            <Input size="large" name="title" placeholder={t('card.form.cardName.placeholder')} />
          </Form.Item>

          {isCorp && (
            <Form.Item
              name="preferredCurrency"
              label={t('card.preferredCurrency')}
              rules={[
                {
                  required: true,
                  message: t('card.form.preferred.currency.error.required'),
                },
              ]}
            >
              <Select
                allowClear
                size="large"
                data-testid="currency-select"
                style={{ width: '100%' }}
                placeholder={t('card.preferredCurrency.placeholder')}
                options={buildCurrencyOptions(profileCurrencies)}
              />
            </Form.Item>
          )}

          {isCardAdmin && isEntityCardManagedByAdmin && (
            <Form.Item
              label={t('card.availableEntities')}
              name="availableToEntityIds"
              rules={[
                {
                  required: true,
                  message: t('card.form.available.entities.error.required'),
                },
              ]}
            >
              <Select
                allowClear
                size="large"
                style={{ width: '100%' }}
                placeholder={t('card.availableEntities.placeholder')}
                options={buildAvailableEntityOptions(availableEntities)}
              />
            </Form.Item>
          )}

          {isCardAdmin && isEntityCardManagedByAdmin && (
            <Form.Item label={t('card.availableProfileGroups')} name="availableToProfileGroupIds">
              <Select
                allowClear
                size="large"
                mode="multiple"
                style={{ width: '100%' }}
                placeholder={t('card.availableProfileGroups.placeholder')}
                options={buildProfileGroupOptions(profileGroups)}
              />
            </Form.Item>
          )}
          {options}

          <Divider />
        </SpaceForm>

        <Button block type="primary" htmlType="submit" loading={isLoading} size="large">
          {submitButtonLabel || t('card.form.submitButton.label')}
        </Button>
      </Form>
    </div>
  )
}

export default CardForm
