import { ThemeConfig } from 'antd'

const theme: ThemeConfig = {
  hashed: false,
  token: {
    colorPrimary: '#3E1D67',
    colorSuccess: '#107E3E',
    colorError: '#d66666',
    borderRadius: 10,
    colorBorder: '#d9d9d9',
    fontFamily:
      'Montserrat,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
  },
  components: {
    Button: {
      primaryShadow: '0 2px 0 rgba(0, 0, 0, 0.045)',
      borderRadiusLG: 50,
      borderRadiusSM: 50,
      borderRadius: 50,
      borderRadiusXS: 50,
      defaultBorderColor: '#3E1D67',
      defaultColor: '#3E1D67',
      defaultBg: 'white',
      colorLink: '#3E1D67',
      colorLinkHover: '#523273',
      colorBgContainerDisabled: '#bea0e4',
      colorTextDisabled: '#ffffff',
      borderColorDisabled: '#bea0e4',
      controlHeight: 40,
      controlHeightLG: 45,
    },
    Form: {
      labelFontSize: 12,
      fontSize: 12,
      fontSizeLG: 12,
      labelColor: '#949393',
      verticalLabelPadding: 0,
      colorError: '#d66666',
      controlHeight: 40,
    },
    Input: {
      fontSize: 12,
      fontSizeLG: 14,
      activeShadow: 'none',
      controlHeight: 40,
    },
    Select: {
      controlHeight: 40,
    },
    InputNumber: {
      controlHeight: 40,
    },
    Dropdown: {
      controlHeight: 40,
    },
    Layout: {
      headerBg: '#3E1D67',
      siderBg: '#3E1D67',
    },
    Alert: {
      colorErrorBg: '#e49999',
      colorErrorBorder: '#ebb3b3',
      fontSize: 12,
      colorWarningBg: '#fff6f0',
      colorWarningBorder: 'transparent',
    },
    Menu: {
      fontSize: 12,
      darkItemColor: '#ffffffe6',
      darkItemBg: '#3E1D67',
      darkSubMenuItemBg: '#3E1D67',
      subMenuItemBorderRadius: 0,
      itemBorderRadius: 0,
    },
    Checkbox: {
      borderRadius: 2,
      borderRadiusLG: 2,
      borderRadiusSM: 2,
      borderRadiusXS: 2,
      colorPrimary: '#9360d2',
    },
    Pagination: {
      borderRadius: 2,
      borderRadiusLG: 2,
      borderRadiusSM: 2,
      borderRadiusXS: 2,
    },
    DatePicker: {
      activeShadow: 'none',
      controlHeight: 40,
    },
    Drawer: {
      colorBgElevated: 'white',
    },
    Tabs: {
      fontWeightStrong: 700,
    },
    Collapse: {
      headerBg: '#fafafa',
    },
    Modal: {},
    Table: {
      rowSelectedBg: '#e0e0e0',
      rowSelectedHoverBg: '#e0e0e0',
      headerBg: '#f2f2f2',
      rowHoverBg: '#fafafa',
      colorBgContainer: 'transparent',
      headerSplitColor: '#ccc',
      colorSplit: '#ccc',
    },
  },
}

export default theme
