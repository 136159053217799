import * as Sentry from '@sentry/react'
import React, { useState, useEffect, useRef } from 'react'
import { Badge, Carousel, Modal } from 'antd'
import { CarouselRef } from 'antd/lib/carousel'
import { ExclamationCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import { sortBy, uniqBy, differenceBy } from 'lodash'
import { useIntl } from 'react-intl'
import AddPayments from './AddPayments/AddPayments'
import ReviewPayments from './ReviewPayments/ReviewPayments'
import SubmitPayments from '../components/SubmitPayments/SubmitPayments'
import SubmitProgress from '../components/SubmitProgress'
import * as piApi from 'api/paymentInstruction'
import * as rulesApi from 'api/rules'
import receiptIcon from 'assets/receipt.svg'
import {
  mapPaymentInstructionToPayment,
  mapTemplateToPayment,
  sortPaymentsByKindAndState,
  getPaymentPage,
  sortPaymentsByKind,
  getTotalNumberOfPages,
} from './utils'
import {
  PaymentInstruction,
  PaymentInstructionTemplate,
  Set,
  Payment,
  PAYMENT_KIND,
  PAYMENT_STATE,
  SetActionsTypes,
  Attachment,
  SetStates,
} from 'types/paymentInstruction'
import { Source } from 'types/source'
import { QueryParams } from 'types/general'
import { SignChainRule } from 'types/rules'
import { page, pi } from 'lang/definitions'
import Page, { MobileMenuOption } from 'components/Page/Page'
import { getSets } from 'utils/init'
import { useHistory } from 'react-router-dom'
import { useWindowSize } from 'hooks/useWindowSize'
import { SCREEN_LG, Size } from 'utils/getDeviceType'
import { FilterBody } from 'components/Filter/Filter'
import { useActiveUser, useSetPolling } from 'hooks'
import { useCurrentPayment } from 'stores/Payment'
import { useCurrentPaymentEffects, useCurrentPaymentUtils, useProgressSlide } from 'stores/Payment/hooks'
import { useSession } from 'stores/session'
import useAddStyle from './Add.style'
import { cx } from 'antd-style'

const Add = (): React.JSX.Element => {
  const [newPayment, setNewPayment] = useState<Payment>()

  const {
    state: { user, rules },
    actions: { setRules },
  } = useSession()
  const history = useHistory()
  const intl = useIntl()
  const { styles } = useAddStyle()
  const size: Size = useWindowSize()
  const { profileId } = useActiveUser()
  const carouselRef = useRef<CarouselRef>(null)

  const signChainRules = rules?.logic.requiredSignChain.rule
  const isSignRequired = signChainRules?.every((rule: SignChainRule) => rule.requiredSignatures !== 0)
  const {
    state: {
      currentTab,
      selectedPayments,
      filteredPayments,
      paymentsOnCurrentPage,
      payments,
      paymentInstructions,
      paymentInstructionTemplates,
      currentPaymentsPage,
      disableReviewPage,
      approvalRounds,
      readySet,
      requireSignatureSet,
      slideNumber,
      isSelectingPayments,
    },
    actions: {
      setSelectedPayments,
      setPayments,
      setPaymentsFilter,
      setPaymentInstructions,
      setPaymentInstructionTemplates,
      setPaymentInstruction,
      setPaymentInstructionTemplate,
      setCurrentPaymentsPage,
      setPaymentCards,
      setCurrencies,
      setNumberOfSignaturesRequired,
      setShowInitLoader,
      setShowReviewPaymentLoader,
      resetState: resetGlobalState,
      dispatchReadySet,
      dispatchRequireSignatureSet,
      dispatchApprovers,
      setIsSelectingPayments,
    },
  } = useCurrentPayment()

  useCurrentPaymentEffects()

  const { getCards, setBeneficiariesState } = useCurrentPaymentUtils()

  const { goToProgressSlide, goBackTwoSlides, handleSlide } = useProgressSlide(carouselRef)

  useEffect(() => {
    updateSelectedPayments({
      paymentInstructionIds: [
        ...(readySet?.paymentInstructionIds || []),
        ...(requireSignatureSet?.paymentInstructionIds || []),
      ],
      paymentInstructionTemplateIds: [
        ...(readySet?.paymentInstructionTemplateIds || []),
        ...(requireSignatureSet?.paymentInstructionTemplateIds || []),
      ],
    })
  }, [readySet, requireSignatureSet])

  useEffect(() => {
    if (readySet?.id && readySet.state === 'PROCESSING') {
      goToProgressSlide()
    }
  }, [readySet, requireSignatureSet])

  const {
    startPolling: startSetPolling,
    resetState: resetPollingState,
    chargedSet,
    chargingSet,
    requireActionSet,
  } = useSetPolling()

  const startPolling = (set: Set) => {
    startSetPolling(set)
    dispatchReadySet(set)
  }

  const initData = async () => {
    setShowInitLoader(true)

    try {
      await getData()
      await Promise.all([getCards(), setBeneficiariesState()])
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      setShowInitLoader(false)
    }
  }

  const loadRules = async () => {
    const updatedRules = await rulesApi.getRules()
    setRules(updatedRules)
  }

  useEffect(() => {
    if (!user || !currentTab) {
      return
    }

    void initData()

    // load rules to present the most fresh version of the rules from the db
    void loadRules()
  }, [user, currentTab])

  const getApproversForRound = async (): Promise<void> => {
    const paymentInstructionIds = requireSignatureSet?.paymentInstructionIds || []
    const searchBody: FilterBody = {
      kind: 'paymentInstruction',
      operator: 'and',
      paymentInstructionId: paymentInstructionIds,
    }
    const { paymentInstructions } = await piApi.searchPaymentInstructions([searchBody])
    let currentRound = 0

    if (paymentInstructions) {
      const { 0: paymentInstruction } = paymentInstructions
      const signedByLength = paymentInstruction?.signatures?.signedBy?.length
      currentRound = (signedByLength || 0) + 1
    }

    const currentApprovalRound = approvalRounds?.find((round) => round.round === currentRound)

    if (currentApprovalRound) {
      dispatchApprovers(currentApprovalRound.users)
    }
  }

  useEffect(() => {
    void getApproversForRound()
  }, [approvalRounds, requireSignatureSet])

  const getPayments = async (): Promise<{
    paymentInstructions: PaymentInstruction[]
    templates: PaymentInstructionTemplate[]
  }> => {
    if (!user) {
      return Promise.reject()
    }

    // Look for SET in PROCESSING
    const processingPIs = await piApi.searchSetByState({
      state: [SetStates.PROCESSING],
      profileId: [user.activeProfileId!],
    })

    const PIsWithChargeGroup = processingPIs.filter((pi) => pi.chargeGroups?.length)
    if (PIsWithChargeGroup.length) {
      startPolling(PIsWithChargeGroup[0])

      return {
        paymentInstructions: [],
        templates: [],
      }
    }

    const readyState = 'ready'
    const requireSignatureState = 'require_signature'
    const params: QueryParams = {
      limit: 0,
    }
    let paymentInstructions: PaymentInstruction[] = []
    let templates: PaymentInstructionTemplate[] = []

    try {
      const [readyPaymentInstructions, readyTemplates, requireSignaturePaymentInstructions, requireSignatureTemplates] =
        await Promise.all([
          piApi.getPaymentInstructionsByState(profileId, readyState, params),
          piApi.getPaymentInstructionTemplatesByState(profileId, readyState, params),
          piApi.getPaymentInstructionsByState(profileId, requireSignatureState, params),
          piApi.getPaymentInstructionTemplatesByState(profileId, requireSignatureState, params),
        ])

      const filteredRequireSignaturePaymentInstructions =
        [...requireSignaturePaymentInstructions].filter((item) => item.signatures?.signatureRequests?.length === 0) ||
        []

      const filteredRequireSignatureTemplates =
        [...requireSignatureTemplates].filter((item) => item.signatures?.signatureRequests?.length === 0) || []

      paymentInstructions = [...readyPaymentInstructions, ...filteredRequireSignaturePaymentInstructions]
      templates = [...readyTemplates, ...filteredRequireSignatureTemplates]
    } catch (error) {
      Sentry.captureException(error)
    }

    return {
      paymentInstructions,
      templates,
    }
  }

  const getData = async (): Promise<void> => {
    const { paymentInstructions, templates } = await getPayments()

    const paymentsFromPaymentInstructions = paymentInstructions.map(mapPaymentInstructionToPayment)
    const paymentsFromTemplates = templates.map(mapTemplateToPayment)

    const sortedPayments = sortBy(
      [...paymentsFromPaymentInstructions, ...paymentsFromTemplates],
      (payment: Payment) => payment.dateDue
    )

    const paymentSources: Source[] = sortedPayments
      .filter((payment: Payment) => !!payment.source)
      .map((payment: Payment) => payment.source!)

    setPayments(sortedPayments)
    setPaymentInstructions(paymentInstructions)
    setPaymentInstructionTemplates(templates)
    setShowReviewPaymentLoader(false)
    if (paymentSources.length) {
      setPaymentCards(uniqBy(paymentSources, (source) => source.id))
    }
  }

  const getDataAndSelectPayments = () => {
    getData().catch((err) => {
      Sentry.captureException(err)
    })
  }

  useEffect(() => {
    if (newPayment) {
      setPaymentsFilter(undefined)
      const paymentPage = getPaymentPage(payments, newPayment.id)
      if (paymentPage !== currentPaymentsPage) {
        setCurrentPaymentsPage(paymentPage)
      }
      setNewPayment(undefined)
    }

    // handle change page if needed when payments are deleted
    const numberOfPages = getTotalNumberOfPages(payments.length)
    if (numberOfPages < currentPaymentsPage) {
      setCurrentPaymentsPage(numberOfPages)
    }
  }, [payments, newPayment])

  useEffect(() => {
    if (payments.length === 0) {
      return
    }

    void getCurrencyOptions()
  }, [payments])

  const getCurrencyOptions = async (): Promise<void> => {
    try {
      const currencies = await rulesApi.getCurrencies()

      setCurrencies(currencies)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const createSet = async (state: PAYMENT_STATE, piIds: string[] = [], templateIds: string[] = []): Promise<void> => {
    const newSet: Set = {
      profileId,
      paymentInstructionState: state,
      additionalIdentifiers: [
        {
          key: 'action',
          value: SetActionsTypes.ADD,
        },
      ],
    }

    if (piIds.length) {
      newSet.paymentInstructionIds = [...piIds]
    }
    if (templateIds.length) {
      newSet.paymentInstructionTemplateIds = [...templateIds]
    }

    try {
      const response = await piApi.createSet(newSet)

      if (response.paymentInstructionState === PAYMENT_STATE.READY) {
        dispatchReadySet(response)
      } else {
        dispatchRequireSignatureSet(response)
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const isOnlyPaymentOnPage = (id: string): boolean => {
    return paymentsOnCurrentPage.length === 1 && paymentsOnCurrentPage[0].id === id
  }

  // used to remove single payment instruction, (use case: replace pi with template)
  const deletePaymentInstruction = async (id: string): Promise<void> => {
    setPaymentInstruction(undefined)
    try {
      const payment = selectedPayments.find((payment: Payment) => payment.id === id)
      if (payment) {
        await deselectPayments([payment])
      }
      await piApi.deletePaymentInstruction(id)
      if (isOnlyPaymentOnPage(id) && currentPaymentsPage > 1) {
        setCurrentPaymentsPage(currentPaymentsPage - 1)
      }
      void getData()
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const deleteTemplate = async (id: string): Promise<void> => {
    setPaymentInstructionTemplate(undefined)
    try {
      const payment = selectedPayments.find((payment: Payment) => payment.id === id)
      if (payment) {
        await deselectPayments([payment])
      }
      await piApi.deletePaymentInstructionTemplate(id)
      if (isOnlyPaymentOnPage(id) && currentPaymentsPage > 1) {
        setCurrentPaymentsPage(currentPaymentsPage - 1)
      }
      void getData()
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  // used to remove array of payments
  const deletePayments = async (payments: Payment[]): Promise<void> => {
    setShowReviewPaymentLoader(true)
    try {
      const selected = payments.filter((payment: Payment) => {
        return selectedPayments.findIndex((selectedPayment: Payment) => selectedPayment.id === payment.id) !== -1
      })

      if (selected) {
        await deselectPayments(selected)
      }
      const { paymentInstructionIds, templateIds } = sortPaymentsByKind(payments)

      await Promise.all([
        ...paymentInstructionIds.map((paymentInstructionId: string) =>
          piApi.deletePaymentInstruction(paymentInstructionId)
        ),
        ...templateIds.map((templateId: string) => piApi.deletePaymentInstructionTemplate(templateId)),
      ])

      void getData()
    } catch (error) {
      setShowReviewPaymentLoader(false)
      Sentry.captureException(error)
    }
  }

  const handleDeletePayment = (payment: Payment): void => {
    showDeleteConfirmationModal([payment])
  }

  const handleDeleteSelectedPayments = (paymentsToDelete = selectedPayments): void => {
    showDeleteConfirmationModal(paymentsToDelete)
  }

  const handleDeleteAllPayments = (): void => {
    showDeleteConfirmationModal(payments)
  }

  const handleEditPayment = (kind: PAYMENT_KIND, id: string): void => {
    if (kind === PAYMENT_KIND.PAYMENT_INSTRUCTION) {
      const pi = paymentInstructions.find((pi: PaymentInstruction) => pi.id! === id)
      setPaymentInstructionTemplate(undefined)
      setPaymentInstruction(pi)
    } else {
      const template = paymentInstructionTemplates.find((template: PaymentInstructionTemplate) => template.id! === id)
      setPaymentInstruction(undefined)
      setPaymentInstructionTemplate(template)
    }
    if ((size.width || 0) < SCREEN_LG) {
      history.goBack()
    }
  }

  const handleCancelEditPayment = (): void => {
    setPaymentInstruction(undefined)
    setPaymentInstructionTemplate(undefined)
  }

  const createPaymentInstruction = async (pi: PaymentInstruction): Promise<PaymentInstruction> => {
    try {
      setShowReviewPaymentLoader(true)
      const paymentInstruction = await piApi.createPaymentInstruction(pi)
      const payment = mapPaymentInstructionToPayment(paymentInstruction)
      await getData()
      await selectPayments([payment])
      setNewPayment(payment)
      return paymentInstruction
    } catch (error) {
      setShowReviewPaymentLoader(false)
      Sentry.captureException(error)
      throw error
    }
  }
  const createPaymentInstructionTemplate = async (
    piTemplate: PaymentInstructionTemplate
  ): Promise<PaymentInstructionTemplate> => {
    try {
      setShowReviewPaymentLoader(true)
      const template = await piApi.createPaymentInstructionTemplate(piTemplate)
      const payment = mapTemplateToPayment(template)
      await getData()
      await selectPayments([payment])
      setNewPayment(payment)
      return template
    } catch (error) {
      setShowReviewPaymentLoader(false)
      Sentry.captureException(error)
      throw error
    }
  }
  const updatePaymentInstruction = async (pi: PaymentInstruction): Promise<PaymentInstruction> => {
    try {
      setShowReviewPaymentLoader(true)
      const updatedPaymentInstruction = await piApi.updatePaymentInstruction(pi)
      await getData()

      const sets = await getSets(profileId)
      dispatchReadySet(sets.readySet)
      if (isSignRequired) {
        dispatchRequireSignatureSet(sets.requireSignatureSet)
      }

      return updatedPaymentInstruction
    } catch (error) {
      setShowReviewPaymentLoader(false)
      Sentry.captureException(error)
      throw error
    }
  }

  const updatePaymentInstructionTemplate = async (
    template: PaymentInstructionTemplate
  ): Promise<PaymentInstructionTemplate> => {
    try {
      setShowReviewPaymentLoader(true)
      const updatedTemplate = await piApi.updatePaymentInstructionTemplate(template)
      await getData()

      const sets = await getSets(profileId)
      dispatchReadySet(sets.readySet)
      if (isSignRequired) {
        dispatchRequireSignatureSet(sets.requireSignatureSet)
      }

      return updatedTemplate
    } catch (error) {
      setShowReviewPaymentLoader(false)
      Sentry.captureException(error)
      throw error
    }
  }

  const replacePaymentInstructionWithTemplate = async (
    template: PaymentInstructionTemplate
  ): Promise<PaymentInstructionTemplate> => {
    try {
      setShowReviewPaymentLoader(true)
      const newTemplate = await piApi.createPaymentInstructionTemplate(template)
      return newTemplate
    } catch (error) {
      setShowReviewPaymentLoader(false)
      Sentry.captureException(error)
      throw error
    }
  }

  const replaceTemplateWithPaymentInstruction = async (
    paymentInstruction: PaymentInstruction
  ): Promise<PaymentInstruction> => {
    try {
      setShowReviewPaymentLoader(true)
      const newPayment = await piApi.createPaymentInstruction(paymentInstruction)
      return newPayment
    } catch (error) {
      setShowReviewPaymentLoader(false)
      Sentry.captureException(error)
      throw error
    }
  }

  const createAttachment = async (paymentId: string, attachment: Attachment, kind: PAYMENT_KIND): Promise<void> => {
    if (kind === PAYMENT_KIND.PAYMENT_INSTRUCTION) {
      try {
        await piApi.createPaymentInstructionAttachment(paymentId, attachment)
        void getData()
      } catch (error) {
        Sentry.captureException(error)
      }
    } else {
      try {
        await piApi.createPaymentInstructionTemplateAttachment(paymentId, attachment)
        void getData()
      } catch (error) {
        Sentry.captureException(error)
      }
    }
  }

  const deleteAttachment = async (paymentId: string, attachmentId: string, kind: PAYMENT_KIND): Promise<void> => {
    if (kind === PAYMENT_KIND.PAYMENT_INSTRUCTION) {
      try {
        await piApi.deletePaymentInstructionAttachment(paymentId, attachmentId)
        void getData()
      } catch (error) {
        Sentry.captureException(error)
      }
    } else {
      try {
        await piApi.deletePaymentInstructionTemplateAttachment(paymentId, attachmentId)
        void getData()
      } catch (error) {
        Sentry.captureException(error)
      }
    }
  }

  const selectAllPayments = (): void => {
    const notSelectedPayments = differenceBy(filteredPayments, selectedPayments, (payment: Payment) => payment.id)
    void selectPayments(notSelectedPayments)
  }

  const deselectAllPayments = (): void => {
    void deselectPayments(filteredPayments)
  }

  const selectPayments = async (payments: Payment[]): Promise<void> => {
    if (isSelectingPayments) {
      return
    }

    setIsSelectingPayments(true)

    setSelectedPayments([...selectedPayments, ...payments])

    const { readyPiIds, readyTemplateIds, requireSignaturePiIds, requireSignatureTemplateIds } =
      sortPaymentsByKindAndState(payments)

    if (readyPiIds.length || readyTemplateIds.length) {
      await addPaymentsToSet(PAYMENT_STATE.READY, readyPiIds, readyTemplateIds)
    }
    if (requireSignaturePiIds.length || requireSignatureTemplateIds.length) {
      await addPaymentsToSet(PAYMENT_STATE.REQUIRE_SIGNATURE, requireSignaturePiIds, requireSignatureTemplateIds)
    }

    setIsSelectingPayments(false)
  }

  const updateSelectedPayments = (set: Partial<Set>) => {
    const paymentIds = set
      .paymentInstructionIds!.map((id: string) => {
        return payments.find((payment: Payment) => payment.id === id)
      })
      .filter((o) => o) as Payment[]

    const templateIds = set
      .paymentInstructionTemplateIds!.map((id: string) => {
        return payments.find((payment: Payment) => payment.id === id)
      })
      .filter((o) => o) as Payment[]

    setSelectedPayments([...paymentIds, ...templateIds])
  }

  const addPaymentsToSet = async (
    state: PAYMENT_STATE,
    piIds: string[] = [],
    templateIds: string[] = []
  ): Promise<void> => {
    const set: Set | undefined = {
      [PAYMENT_STATE.READY]: readySet,
      [PAYMENT_STATE.REQUIRE_SIGNATURE]: requireSignatureSet,
      [PAYMENT_STATE.PENDING]: undefined,
    }[state]

    const dispatchSet = {
      [PAYMENT_STATE.READY]: dispatchReadySet,
      [PAYMENT_STATE.REQUIRE_SIGNATURE]: dispatchRequireSignatureSet,
      [PAYMENT_STATE.PENDING]: () => {},
    }[state]

    if (set?.id) {
      if (piIds.length) {
        try {
          const updatedSet = await piApi.addPaymentInstructionToSet(set.id, piIds)
          dispatchSet(updatedSet)
        } catch (error) {
          Sentry.captureException(error)
          const payments = selectedPayments.filter((payment: Payment) => !piIds.includes(payment.id))
          setSelectedPayments(payments)
        }
      }

      if (templateIds.length) {
        try {
          const updatedSet = await piApi.addTemplateToSet(set.id, templateIds)
          dispatchSet(updatedSet)
        } catch (error) {
          Sentry.captureException(error)
          const payments = selectedPayments.filter((payment: Payment) => !templateIds.includes(payment.id))
          setSelectedPayments(payments)
        }
      }
    } else {
      void createSet(state, piIds, templateIds)
    }
  }

  const deselectPayments = async (deselectedPayments: Payment[]): Promise<void> => {
    if (isSelectingPayments) {
      return
    }

    setIsSelectingPayments(true)

    const { readyPiIds, readyTemplateIds, requireSignaturePiIds, requireSignatureTemplateIds } =
      sortPaymentsByKindAndState(deselectedPayments)

    if (readyPiIds.length && readySet?.id) {
      await piApi.removePaymentInstructionFromSet(readySet.id, readyPiIds)
    }

    if (readyTemplateIds.length && readySet?.id) {
      await piApi.removeTemplateFromSet(readySet.id, readyTemplateIds)
    }

    if (requireSignaturePiIds.length && requireSignatureSet?.id) {
      await piApi.removePaymentInstructionFromSet(requireSignatureSet.id, requireSignaturePiIds)
    }

    if (requireSignatureTemplateIds.length && requireSignatureSet?.id) {
      await piApi.removeTemplateFromSet(requireSignatureSet.id, requireSignatureTemplateIds)
    }

    const sets = await getSets(profileId)
    dispatchReadySet(sets.readySet)
    if (isSignRequired) {
      dispatchRequireSignatureSet(sets.requireSignatureSet)
    }

    const payments = differenceBy(selectedPayments, deselectedPayments, (payment: Payment) => payment.id)

    setSelectedPayments(payments)

    setIsSelectingPayments(false)
  }

  const getNumberOfSignaturesRequired = (paymentIds: string[]): void => {
    const paymentsFromSet = paymentIds.map(
      (id: string): Payment => payments.find((payment: Payment) => payment.id === id)!
    )

    let numberOfSignaturesRequired = 0

    paymentsFromSet
      .filter((payment: Payment) => !!payment)
      .forEach((payment: Payment) => {
        const { signatures: { requiredNumber = 0 } = {} } = payment
        if (requiredNumber && requiredNumber > numberOfSignaturesRequired) {
          numberOfSignaturesRequired = requiredNumber
        }
      })

    setNumberOfSignaturesRequired(numberOfSignaturesRequired)
  }

  const showDeleteConfirmationModal = (payments: Payment[]) => {
    Modal.confirm({
      title: intl.formatMessage(pi['pi.modal.delete.header']),
      icon: <ExclamationCircleOutlined />,
      content: intl.formatMessage(pi['pi.modal.delete.text'], {
        numberOfPayments: payments.length,
      }),
      okText: intl.formatMessage(pi['pi.modal.button.confirmDelete']),
      cancelText: intl.formatMessage(pi['pi.modal.button.cancel']),
      onOk: () => deletePayments(payments),
      okButtonProps: {
        style: {
          backgroundColor: '#C15A5A',
          border: 'none',
          outline: 'none',
          fontWeight: 'bold',
        },
      },
    })
  }

  const resetState = (): void => {
    resetGlobalState()
    resetPollingState()
  }

  const handleGoBackAfterSubmit = (): void => {
    goBackTwoSlides()

    // Wait for transition animation to finish
    setTimeout(() => {
      resetState()
      void initData()
    }, 500)
  }

  const mobileMenuOptions: Array<MobileMenuOption> = []

  return (
    <Page
      title={intl.formatMessage(page['page.add.payments.page.title'])}
      mobileMenuOptions={mobileMenuOptions}
      bhTrackClassName={styles.addPaymentsPage}
    >
      <div className={styles.payContainer}>
        {payments && payments.length ? (
          <span onClick={() => handleSlide()}>
            {slideNumber ? (
              <ArrowLeftOutlined className={styles.backBtn} />
            ) : (
              <Badge count={payments.length} className={styles.mobilePaymentCounts}>
                <img className={styles.receiptIcon} src={receiptIcon} />
              </Badge>
            )}
          </span>
        ) : null}

        <Carousel
          ref={carouselRef}
          className={cx(styles.payCarousel, styles.add)}
          slidesToShow={2}
          dots={false}
          infinite={false}
          swipeToSlide={false}
          swipe={false}
          responsive={[
            {
              breakpoint: SCREEN_LG,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
              },
            },
          ]}
        >
          <div className={styles.payContainerStep}>
            <AddPayments
              getDataAndSelectPayments={getDataAndSelectPayments}
              createPaymentInstruction={createPaymentInstruction}
              deletePaymentInstruction={(id) => void deletePaymentInstruction(id)}
              deleteTemplate={(id) => void deleteTemplate(id)}
              createPaymentInstructionTemplate={createPaymentInstructionTemplate}
              updatePaymentInstruction={updatePaymentInstruction}
              updatePaymentInstructionTemplate={updatePaymentInstructionTemplate}
              replacePaymentInstructionWithTemplate={replacePaymentInstructionWithTemplate}
              replaceTemplateWithPaymentInstruction={replaceTemplateWithPaymentInstruction}
              cancelEdit={handleCancelEditPayment}
              createAttachment={createAttachment}
              deleteAttachment={deleteAttachment}
            />
          </div>
          <div
            className={cx(styles.payContainerStep, styles.scrollable, styles.disableScrollbars, {
              [styles.disabled]: disableReviewPage,
            })}
          >
            <ReviewPayments
              selectAllPayments={selectAllPayments}
              deselectAllPayments={deselectAllPayments}
              selectPayments={(payments) => void selectPayments(payments)}
              deselectPayments={(payments) => void deselectPayments(payments)}
              handleEditPayment={handleEditPayment}
              handleDeletePayment={handleDeletePayment}
              getNumberOfSignaturesRequired={getNumberOfSignaturesRequired}
              handleDeleteSelectedPayments={handleDeleteSelectedPayments}
              handleDeleteAllPayments={handleDeleteAllPayments}
              goToProgressSlide={goToProgressSlide}
              startPolling={startPolling}
              submitButton={
                <SubmitPayments
                  requireSignatureSet={requireSignatureSet}
                  goToProgressSlide={goToProgressSlide}
                  requireActionSet={requireActionSet}
                  triggerProcessingPaymentsSearch={true}
                  startPolling={startPolling}
                />
              }
            />
          </div>
          <div
            className={
              (cx(styles.payContainerStep, styles.withBackground), styles.scrollable, styles.disableScrollbars)
            }
          >
            <SubmitProgress
              chargingSet={chargingSet}
              chargedSet={chargedSet}
              goBackAfterSubmit={handleGoBackAfterSubmit}
            />
          </div>
        </Carousel>
      </div>
    </Page>
  )
}

export default Add
